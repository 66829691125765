import { v4 as uuidv4 } from "uuid";
import { enums } from "@optimizely/optimizely-sdk";
import type { Client, OptimizelyUserContext } from "@optimizely/optimizely-sdk";
import { datadogRum } from "@datadog/browser-rum";
import useDataLayer, {
  CheckoutProductsType,
  fetchMerchantAccounts,
} from "../useDataLayer";
import {
  bracketSelectorRegExp,
  phoneRegExp,
  emailRegExp,
  whiteSpaceRegExp,
  urlRegExp,
} from "../regexp";
import type { AdminType } from "../../configs/single-donation";
import { getFormArchetype } from "../../configs/single-donation";
import type { Brand, Option } from "../types/shared";

const brandArray = ["CRUK", "SU2C", "RFL", "BMF"];
const isBrand = (brand: string): brand is Brand => brandArray.includes(brand);

export const toBrand = (brand?: string): Brand | undefined =>
  brand ? (isBrand(brand) ? brand : undefined) : undefined;

export const getThemeAndBrand = (
  brand?: Brand
): { theme: string; brand: string } =>
  brand
    ? {
        theme: brand === "SU2C" ? "SU2C" : "CRUK",
        brand: brand as string,
      }
    : {
        theme: "CRUK",
        brand: "CRUK",
      };

export const isProductJourney = (merchantAccount: string): boolean =>
  fetchMerchantAccounts().get(merchantAccount)?.journey === "product";

export const isDonationJourney = (merchantAccount: string): boolean =>
  fetchMerchantAccounts().get(merchantAccount)?.journey === "donation";

export type TransactionAttemptCache = Partial<{
  sellerReference: string;
  redirectUrl: string;
  timestamp: number; // can be used to say "last attempted at: time"
}>;

export const setTransactionAttemptRedirectUrl = (redirectUrl: string) => {
  const stored = localStorage.getItem("transaction-attempt");
  const existingData = stored && JSON.parse(stored);
  const data = {
    ...existingData,
    redirectUrl,
  } as TransactionAttemptCache;
  localStorage.setItem("transaction-attempt", JSON.stringify(data));
};

export const setTransactionAttemptSellerReference = (
  sellerReference: string
) => {
  const stored = localStorage.getItem("transaction-attempt");
  const existingData = stored && JSON.parse(stored);
  const data = {
    ...existingData,
    sellerReference,
    timestamp: new Date().getTime(),
  } as TransactionAttemptCache;
  localStorage.setItem("transaction-attempt", JSON.stringify(data));
};

export const clearTransactionAttemptCache = () => {
  const stored = localStorage.getItem("transaction-attempt");
  if (stored) {
    const existingData = stored && JSON.parse(stored);
    const data = {
      redirectUrl: existingData?.redirectUrl,
    } as TransactionAttemptCache;
    localStorage.setItem("transaction-attempt", JSON.stringify(data));
  }
};

export const removeTransactionAttemptCache = () => {
  localStorage.removeItem("transaction-attempt");
};

const RETRY_THRESHOLD_TIME_SECONDS = 90;

const canRetryTransaction = (cachedTime: number): boolean => {
  const currentTime = new Date().getTime();
  const deltaMillis = currentTime - cachedTime;
  const deltaSeconds = deltaMillis / 1000;
  return deltaSeconds > RETRY_THRESHOLD_TIME_SECONDS;
};

export const getTransactionAttemptCache =
  (): Option<TransactionAttemptCache> => {
    const item = localStorage.getItem("transaction-attempt");
    const cache = item && (JSON.parse(item) as TransactionAttemptCache);

    if (canRetryTransaction(cache?.timestamp)) {
      clearTransactionAttemptCache();
      return null;
    }

    return cache;
  };

type DataArrayType = DataObject[];
type DataObject = {
  [i: string]: string;
};

export const setCookieBanner = (): void => {
  const cookieBannerID =
    process.env.COOKIE_BANNER_ID === "PRODUCTION"
      ? "81232513-709c-4b95-8a41-a4b812c22fdc"
      : "81232513-709c-4b95-8a41-a4b812c22fdc-test";
  const cookieScript = document.createElement("script");
  cookieScript.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
  cookieScript.setAttribute("data-domain-script", cookieBannerID);

  document.head.appendChild(cookieScript);
};

export const setCookieAccepted = (
  brand: string,
  page: string,
  amount?: number,
  amountWithGA?: number,
  checkoutStep?: number,
  dataLayerObj?: CheckoutProductsType[],
  transactionId?: string,
  paymentMethod?: string,
  productSource?: string,
  restriction?: string
): void => {
  const { pushDataLayerLoaded, pushCheckoutStep, pushPaymentSuccess } =
    useDataLayer();
  const { theme, brand: brandName } = getThemeAndBrand(toBrand(brand));

  // @ts-expect-error
  window.OptanonWrapper = () => {
    pushDataLayerLoaded(brandName, page);
    if (checkoutStep) {
      if (checkoutStep < 4) {
        dataLayerObj ? pushCheckoutStep(checkoutStep, dataLayerObj) : "";
      }
      if (checkoutStep === 4 && transactionId) {
        pushPaymentSuccess(
          amount,
          amountWithGA,
          transactionId,
          paymentMethod,
          theme,
          productSource,
          restriction,
          dataLayerObj,
          ""
        );
      }
    }
  };
};

/**
 * Regex: `/target=([^;]+)(?:;|$)/`
 *
 * Cookie strings follow this pattern: "cookie1=values1;cookie2=values2;cookie3=values3;cookie4=values4;"
 *
 * The above regex is to capture the value of a specific cookie. Therefore, to match "values1", the regex would become `/cookie1=([^;]+);/`
 *
 * Explanation:
 * - cookie1 matches exactly
 * - = matches the equal sign.
 * - ([^;]+) captures one or more characters that are not a semicolon.
 * - (?:;|$) is a non-capture group that matches whether the string ends with ; or end of string
 *
 * JavaScript's `.match` method returns an array in the following format if there is a match:
 *
 * Array [entire match, ...all capturing groups]
 *
 * For example, for the above search term:
 * ```javascript
 * const cookies = "cookie1=values1;cookie2=values2;cookie3=values3;cookie4=values4;";
 * const pattern = /cookie1=([^;]+)(?:;|$)/;
 *
 * const match = cookies.match(pattern); // returns ["cookie1=values1;", "values1"]
 * ```
 */
const findCookie = (cookieString: string, target: string): Option<string> => {
  const pattern = new RegExp(`${target}=([^;]+)(?:;|$)`);
  const match = cookieString.match(pattern)?.[1];
  return match && decodeURIComponent(match);
};

/**
 * Regex: `/groups=([^&]+)(?:&|$)/`
 *
 * The consent portion of the OptanonConsent cookie looks like this: "groups=snc:1,per:1,fun:1,tar:1"
 *
 * The cookie value is separated by `&` and this portion could exist somewhere in between or the end of string.
 *
 * Explanation:
 * - groups matches exactly
 * - = matches the equal sign.
 * - ([^&]+) captures one or more characters that are not an ampersand.
 * - (?:&|$) is a non-capture group that matches whether the string ends with & or end of string
 */
const peekCookieConsentString = (): Option<string> => {
  const consentCookie = findCookie(document.cookie, "OptanonConsent");
  const match = consentCookie?.match(/groups=([^&]+)(?:&|$)/);
  return match?.[1];
};

type OfflineConversionData = {
  fbc: Option<string>;
  fbp: Option<string>;
  consentString: Option<string>;
  eventSourceUrl: string;
  archetype: ReturnType<typeof getFormArchetype>;
};

export const getOfflineConversionData = (
  formName?: string
): OfflineConversionData => {
  const consentString = peekCookieConsentString();
  const fbc = findCookie(document.cookie, "_fbc");
  const fbp = findCookie(document.cookie, "_fbp");
  const eventSourceUrl = window.location.href;
  const archetype = getFormArchetype(formName);

  return {
    consentString,
    eventSourceUrl,
    fbc,
    fbp,
    archetype,
  };
};

export const getReCaptchaIdByJourney = (page: string) => {
  let result: Option<{ id: string; journey: "donation" | "product" }> = null;

  const singlePathTemplate = "/[form]/";
  const donateLandingPageTemplate = "/donate";
  const productPathTemplate = "/payment";

  const isProductPage = page === productPathTemplate;
  const isDonationPage =
    page.startsWith(singlePathTemplate) ||
    page.startsWith(donateLandingPageTemplate);

  if (isProductPage) {
    result = {
      id:
        process.env.PA_ENT_RECAPTCHA_ID ||
        "6LfX6K0cAAAAABcxOdLV-131IQuBjz5JZantzqQd",
      journey: "product",
    };
  } else if (isDonationPage) {
    result = {
      id:
        process.env.SD_ENT_RECAPTCHA_ID ||
        "6Lc1pq0cAAAAAEquhg5jOP-q4WRI0MY0e0S90INs",
      journey: "donation",
    };
  }

  return result;
};

// @ts-ignore
// export const setReCAPTCHA = (reCaptchaId: string): void => {
//   const reCaptchaScript = document.createElement("script");
//   // reCaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaId}`;
//   reCaptchaScript.src = `https://www.google.com/recaptcha/enterprise.js?render=${reCaptchaId}`;
//   document.head.appendChild(reCaptchaScript);
// };

export const setReCAPTCHAVisibility = (
  visibility: "hidden" | "visible"
): void => {
  const recaptchaStyleElement = document.getElementById(
    "recaptcha-visibility"
  ) as Option<HTMLStyleElement>;
  const stylesheet = recaptchaStyleElement?.sheet as any;
  const currentVisibility = stylesheet?.cssRules[0].style.getPropertyValue(
    "--visibility"
  ) as string;
  if (currentVisibility !== visibility) {
    stylesheet?.cssRules[0].style.setProperty("--visibility", visibility);
  }
};

export const setDatadog = (): void => {
  if (process.env.NODE_ENV === "development") {
    return null;
  }
  datadogRum.init({
    applicationId: "a4e3c5be-5896-45d8-939c-91ad2577afcf",
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: "datadoghq.eu",
    service: "PWS",
    env: process.env.DATADOG_ENV,
    version: "1.94.0",
    sessionSampleRate: 100,
    sessionReplaySampleRate: process.env.DATADOG_ENV === "production" ? 10 : 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    silentMultipleInit: true,
  });
  datadogRum.startSessionReplayRecording();
};

export const getUKDate = (date: Date): string => {
  let dd = String(date.getDate());
  let mm = String(date.getMonth() + 1);
  const yyyy = date.getFullYear();

  if (mm.length < 2) mm = `0${mm}`;
  if (dd.length < 2) dd = `0${dd}`;

  const today = `${dd}/${mm}/${yyyy}`;
  return today;
};

export const isUkCountry = (country: string): boolean =>
  ["United Kingdom", "Jersey", "Guernsey", "Isle of Man"].includes(country);

export const isNonMainlandPostcode = (postcode: string): boolean => {
  const firstTwoChars = postcode.substring(0, 2);
  const ukNonMainlandPostcodes = ["GY", "JE", "IM"];
  return ukNonMainlandPostcodes.includes(firstTwoChars.toUpperCase());
};

export const formatTwoDecimals = (num: number): number =>
  Number(num.toFixed(2));

export const getPageTitle = (): string => {
  if (
    window.location.pathname.includes("/") &&
    window.location.pathname.split("/").length > 2
  ) {
    const page = window.location.pathname.includes("/")
      ? window.location.pathname.split("/")[2]
      : window.location.pathname;
    const title = page.includes("-") ? page.replace("-", " ") : page;
    const formattedTitle = title.charAt(0).toUpperCase() + title.slice(1);
    return formattedTitle;
  }
  return "404";
};

export const idleTimer = (brand: string): void => {
  let time: ReturnType<typeof setTimeout>;

  const resetSession = () => {
    sessionStorage.clear();
    redirectToHomepage(brand);
  };

  const resetTimer = () => {
    clearTimeout(time);
    time = setTimeout(resetSession, 900000);
  };

  window.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onmousedown = resetTimer;
  document.onkeypress = resetTimer;
  document.ontouchstart = resetTimer;
  document.onclick = resetTimer;
  document.onkeydown = resetTimer;
  document.addEventListener("scroll", resetTimer, true);
};

export const redirectToHomepage = (brand: string): void => {
  let url;

  switch (brand) {
    case "SU2C":
      url = "https://www.standuptocancer.org.uk/";
      break;
    case "BMF":
      url = "https://www.cancerresearchuk.org/get-involved/bobby-moore-fund";
      break;
    case "RFL":
      url = "https://raceforlife.cancerresearchuk.org/";
      break;
    default:
      url = "https://www.cancerresearchuk.org/";
      break;
  }

  sessionStorage.clear();

  window.location.replace(url);
};

export const getOptimizelyUserId = (): Option<string> => {
  let uuid;
  if (
    typeof window !== "undefined" &&
    sessionStorage.getItem("optimizelyUserId")
  ) {
    uuid = sessionStorage.getItem("optimizelyUserId");
  } else {
    uuid = uuidv4();
    if (typeof window !== "undefined")
      sessionStorage.setItem("optimizelyUserId", uuid);
  }

  return uuid;
};

const OptimizelyAudience = [
  "test",
  "general",
  "condensed-amount-view-bucket",
] as const;

export type Audience = (typeof OptimizelyAudience)[number];

export const setOptimizelyAudience = (audience: Audience): void => {
  try {
    sessionStorage.setItem("optimizelyAudience", audience);
    console.info(`[OPTIMIZELY] Cached optimizely audience: ${audience}`);
  } catch (e) {
    console.error("Could not set optimizely audience to session storage.");
  }
};

export const getOptimizelyAudience = (): Option<Audience> => {
  let audience: Audience;

  if (typeof window !== "undefined") {
    const searchParams = new URLSearchParams(window.location.search);
    const testingMode = searchParams.get("testingmode");

    if (testingMode === "true") {
      setOptimizelyAudience("test");
      audience = "test";
    } else if (testingMode === "false") {
      setOptimizelyAudience("general");
      audience = "general";
    } else {
      const cachedAudience = sessionStorage.getItem(
        "optimizelyAudience"
      ) as Option<Audience>;

      if (cachedAudience) {
        audience = cachedAudience;
      } else {
        // TODO This might need to be tweaked to revert back to original audience (if anything other than test and general are introduced)
        setOptimizelyAudience("general");
        audience = "general";
      }
    }
  }

  return audience;
};

export type FetchOptimizelyDatafileResponse = {
  datafile: Option<string>;
  status: "cached" | "new" | "timedout";
};

export const cacheOptimizelyDatafile = (datafile: string) => {
  if (typeof window !== "undefined") {
    try {
      sessionStorage.setItem("optimizelyDatafile", datafile);
      console.info("[OPTIMIZELY] Cached datafile.");
    } catch (e) {
      console.error(
        "[OPTIMIZELY] Could not cache optimizely datafile to session storage.",
        e
      );
    }
  }
};

export const fetchOptimizelyDatafile = async (
  sdkKey: string,
  timeout = 5000
): Promise<Option<FetchOptimizelyDatafileResponse>> => {
  if (typeof window !== "undefined") {
    const cachedDatafile = sessionStorage.getItem("optimizelyDatafile");
    if (cachedDatafile) {
      console.info("[OPTIMIZELY] Returning cached datafile.");

      return {
        status: "cached",
        datafile: cachedDatafile,
      };
    }

    const controller = new AbortController();
    const timer = setTimeout(() => {
      controller.abort();
    }, timeout);

    try {
      const datafileUrl = `https://cdn.optimizely.com/datafiles/${sdkKey}.json`;
      const response = await fetch(datafileUrl, { signal: controller.signal });
      // On error, this returns an XML error response. This doesn't break the app, because the init just requests a fresh datafile. As long as the sdkKeys are the same, the fetch to the CDN is the exact same.
      const datafile = await response.text();
      cacheOptimizelyDatafile(datafile);
      console.info("[OPTIMIZELY] Returning fetched datafile.");

      return {
        status: "new",
        datafile,
      };
    } catch (e) {
      console.error("[OPTIMIZELY] Could not manually fetch datafile.", e);

      return {
        datafile: null,
        status: "timedout",
      };
    } finally {
      clearTimeout(timer);
    }
  }

  return null;
};

/**
 * Regex: `/(\[.*?\])/`
 *
 * Explanation:
 * - `(` and `)`: These parentheses define a capture group, which means that anything matched within these parentheses will be captured and included in the result of the split method.
 * - `\]`: This matches the right square bracket `]` character.
 * - `.*?`: The `.*?` is a non-greedy quantifier that matches any character zero or more times but as few times as possible. It ensures that the regular expression captures the shortest possible string between square brackets.
 *
 * - So, the entire regular expression `/(\[.*?\])/` is designed to match and capture any content enclosed within square brackets while splitting the string at those brackets. The captured content is what you get in the resulting array when using split.
 * Examples:
 * - Raw: "This is a string with [phone number] and [email]."
 * - Result when split with capture group regex: Array ["This is a string with ", "[phone number]", " and ", "[email]", "."]
 */
export const splitIntoFragments = (copy: string): Array<string> =>
  copy
    .trim()
    .split(/(\[.*?\])/)
    .filter((sub) => sub.trim() !== "");

export const isTemplate = (fragment: string): boolean =>
  fragment.startsWith("[") && fragment.endsWith("]");

export const stripTemplating = (fragment: string): string =>
  fragment.replace(bracketSelectorRegExp, "");

export const internationalizePhoneNumber = (
  phoneNumber: string,
  countryCallingCode = "+44"
): string => `${countryCallingCode}${phoneNumber.substring(1)}`;

export const removeMiddleSpaces = (str: string): string =>
  str.replace(whiteSpaceRegExp, "");

export const isPhoneNumber = (fragment: string): boolean =>
  phoneRegExp.test(removeMiddleSpaces(stripTemplating(fragment)));

export const isEmail = (fragment: string): boolean =>
  emailRegExp.test(removeMiddleSpaces(stripTemplating(fragment)));

export const isUrl = (fragment: string): boolean =>
  urlRegExp.test(removeMiddleSpaces(stripTemplating(fragment)));

export const clickableTelNum = (tel: string): string =>
  `<a href="tel:${internationalizePhoneNumber(
    removeMiddleSpaces(tel)
  )}">${tel}</a>`;

export const canUseApplePay = (): boolean => {
  if (
    window.ApplePaySession &&
    window.ApplePaySession.supportsVersion(3) &&
    window.ApplePaySession.canMakePayments()
  ) {
    return true;
  }
  return false;
};

/**
 * This function expects the name to have already gone through our schema validation for the cardholderName field.
 *
 * @throws Will throw an error if name does not contain a space that can be used to split the name into givenName and surname.
 */
export const approximateCardholderNameToParts = (
  fullName: string,
  options: { tag: string } = { tag: "Payment" }
): { givenName: string; surname: string } => {
  const name = fullName.trim();
  const separatorIndex = name.lastIndexOf(" ");

  if (separatorIndex === -1) {
    throw new Error(
      `[${options.tag}] Could not approximate cardholder name into given name and surname: '${name}'`
    );
  }

  const surname = name.substring(separatorIndex + 1).trim();
  const givenName = name.substring(0, separatorIndex).trim();

  return {
    givenName,
    surname,
  };
};

export const getTransactionID = (formName: string): string | null =>
  typeof window !== "undefined"
    ? sessionStorage.getItem(`transactionId-${formName}`)
    : null;

export const pageShowEventReload = (e: PageTransitionEvent) => {
  if (e.persisted) {
    window.location.reload();
  }
};

export const handleMetaData = (
  initialValue: any,
  researchAreas: Array<{ label: string; value: string }>,
  formName: string,
  formAdmin: AdminType,
  brand: string,
  formType: "single" | "regular" = "single"
): any => {
  const refData = {
    admin: {
      formID: formAdmin.formID,
      product:
        formType === "regular"
          ? formAdmin.product.regular
          : initialValue.typeRadioGroup === "yes"
          ? formAdmin.product.single
          : formAdmin.product.collection,
      source:
        formType === "regular"
          ? formAdmin.source.regular
          : initialValue.typeRadioGroup === "yes"
          ? formAdmin.source.single
          : formAdmin.source.collection,
      emailType:
        formType === "regular"
          ? formAdmin.emailType.regular
          : formAdmin.emailType.single,
    },
  };

  const researchArea = initialValue.restriction
    ? researchAreas.find((area) => area.value === initialValue.restriction)
    : "";
  const restrictionDesc = researchArea
    ? { restrictionDescription: researchArea.label.toLowerCase() }
    : "";

  const trimmedPostcode = { postalCode: initialValue.postalCode.trim() };
  const trimmedBillingPostcode = {
    billingPostalCode: initialValue.billingPostalCode.trim(),
  };
  const offlineConversionData = getOfflineConversionData(formName);

  return {
    ...initialValue,
    ...trimmedPostcode,
    ...trimmedBillingPostcode,
    ...restrictionDesc,
    ...refData,
    marketingProperties: offlineConversionData,
    theme: brand,
  };
};

export const mergeMetadata = (metadata: any, donationData?: any) => {
  const trimmedPostcode = { postalCode: donationData.postalCode.trim() };
  const trimmedBillingPostcode = {
    billingPostalCode: donationData.billingPostalCode.trim(),
  };
  const mergedMetadata = donationData
    ? { ...metadata, ...donationData }
    : metadata;

  return {
    ...mergedMetadata,
    ...trimmedPostcode,
    ...trimmedBillingPostcode,
  };
};

export const objectHasVal = (
  obj: DataArrayType,
  key: string,
  val: string
): boolean => obj.filter((e) => e[key] === val).length > 0;

export const optimizelyNotificationListeners = (optimizely: Client) => {
  const { pushOptimizelyExperiment } = useDataLayer();
  const onDecision = ({ type, decisionInfo }: any): void => {
    if (type === "flag") {
      pushOptimizelyExperiment(
        decisionInfo.flagKey,
        decisionInfo.enabled,
        decisionInfo.variationKey
      );
    }
  };

  optimizely.notificationCenter.addNotificationListener(
    enums.NOTIFICATION_TYPES.DECISION,
    onDecision
  );
};

export const checkOptimizelyVariation = ({
  user,
}: {
  user: OptimizelyUserContext;
}): void => {
  // Optimizely check for variation URL param (variation) and force specific decision
  if (user) {
    const searchParams = new URLSearchParams(window.location.search);
    const optimizelyExperiment = searchParams.get("experiment");
    const optimizelyVariation = searchParams.get("variation");

    if (optimizelyExperiment && optimizelyVariation) {
      console.info(
        `[OPTIMIZELY] Forcing variation\nExperiment: ${optimizelyExperiment}\nVariation: ${optimizelyVariation}`
      );
      user.setForcedDecision(
        { flagKey: optimizelyExperiment },
        { variationKey: optimizelyVariation }
      );
    }
  }
};

export const PAYMENT_API_GATEWAY_URL =
  process.env.PAYMENT_API_GATEWAY_URL || "https://api.pws.int.cruk.org/";

export const formatNumberToCurrencyString = (
  num: number,
  includeDecimal?: boolean
): string =>
  includeDecimal
    ? num.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : num.toLocaleString();

export const testables = {
  findCookie,
  peekCookieConsentString,
};
